import React from "react";
import EmotionCodeCert from "./assets/CECP-JZ.png";
import BodyCodeCert from "./assets/CBCP-JZ.png";
import NDCert from "./assets/ND-JZ.png";
import "./AboutBodyCode.css";

const AboutBodyCode = () => <div>
  <div className="aboutme">
    <p className="center-word">The Mind-Body Energy Healing</p>
    <p className="center-word-small">The Ultimate Health, Wealth & Happiness Solution</p>
    <p className="center-word">Healing your Body and Heart</p>
    <p className="center-word-small">Jenny Zhang - Board Certified Doctor of Naturopathy, Certified Emotion Code, Body Code Practitioner</p>
    <p className="bodycode-intro">&nbsp; &nbsp; Everything in this universe is made of nothing but pure, vibrating energy.
    The Mind-Body Energy Healing is all about finding the causes of disease and caring for the human body in the healthiest and most complete way.
      My job is to communicate with your subconscious through energy connecting and muscle testing to identify and correct imbalances that cause all kinds of physical and emotional problems for people, to release
      trapped emotional energies (emotional baggage) and correct blocked chakras and meridians. By removing these causes
      of disease and creating a state of balance in the body, create perfect conditions for the body to heal itself and
      stay health.
    </p>
    <p>
    Mind-Body Energy Healing can help you to:
      <ul>
        <li>Restore your health</li>
        <li>Avoid serious illness</li>
        <li>Reduce or eliminate pain</li>
        <li>De-stress your life</li>
        <li>Build healthier relationships</li>
        <li>Help you to find a deeper sense of life purpose</li>
        <li>Bring contentment and happiness to your heart</li>
        <li>Help you to create more success and abundance</li>
      </ul>
    </p>
    <div className="images">
      <img className="bodycode-cert" src={BodyCodeCert}/>
      <img className="nd-cert" src={NDCert}/>
      <img className="emotioncode-cert" src={EmotionCodeCert}/>
    </div>
  </div>
</div>;

export default AboutBodyCode;