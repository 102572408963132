import {Collapse, Container, NavbarNav, NavItem, NavLink, NavbarToggler} from "mdbreact";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        height: 180,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    menu: {
        margin: '5px'
    }
});

class HeaderCollapse extends Component {
    state = {
        collapsed: false
    };

    handleTogglerClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };
   render(){
       const { classes } = this.props;
       return (
        <div className={classes.container}>
           <NavbarToggler onClick={this.handleTogglerClick} />
           <Collapse isOpen={this.state.collapsed} navbar  className={classes.container}>
               <NavbarNav  className={classes.container}>
                 <NavItem className={classes.menu}>
                   <NavLink to="/">Home</NavLink>
                 </NavItem>
                 <NavItem className={classes.menu}>
                   <NavLink active to="/aboutbodycode">About Mind-Body Energy Healing</NavLink>
                 </NavItem>
                 <NavItem className={classes.menu}>
                   <NavLink active to="/schedule">Schedule an appointment</NavLink>
                 </NavItem>
                 <NavItem className={classes.menu}>
                   <NavLink active to="/testimonials">Testimonials</NavLink>
                 </NavItem>
                 <NavItem className={classes.menu}>
                   <NavLink active to="/contactus">Contact</NavLink>
                 </NavItem>
               </NavbarNav>
           </Collapse>
        </div>
       );
   }
}

export default withStyles(styles)(HeaderCollapse)
